<i18n>
    [
        "global__previous",
        "global__next",
    ]
</i18n>
<template>
    <div
        v-if="carouselItems.length"
        :class="{
            'c-carousel': true,
            'c-carousel--no-padding': noSlidePadding,
            'c-carousel--no-left-padding': noLeftPadding,
            'c-carousel--no-right-padding': noRightPadding,
            'c-carousel--small-padding': containerPaddingSize === 'small'
        }"
    >
        <div
            v-tab-focus="handleLinkClick"
            :class="{
                'c-carousel__container--no-margin': noContainerMargin,
                'c-carousel__container--link': wrappingLink,
            }"
            class="c-carousel__container"
            @keyup.left="scrollToPrev"
            @keyup.right="scrollToNext"
        >
            <ul
                :id="scrollContainerId"
                ref="scrollContainer"
                class="c-carousel__slide-container"
                tabindex="-1"
            >
                <li
                    v-for="(carouselItem, index) in carouselItems"
                    :ref="setSlideRef"
                    :key="`carousel-${name}-${index}`"
                    :class="{
                        [slideClass]: !!slideClass,
                        'c-carousel__slide': true,
                        'c-carousel__slide--fixed-width': !useVariableWidth,
                        'c-carousel__slide--snap-start': isSwiping,
                        'c-carousel__slide--snap-stop': scrollSnapStop,
                    }"
                >
                    <!--
                            by default, the slot name will be carouselItem-${index}
                            carouselItemUniqueKey is used when the number of items in a carousel can change dynamically
                            i.e. closeted products tray, an item can be removed
                        -->
                    <slot :name="getCarouselSlotName(carouselItem,carouselItemUniqueKey,index)"></slot>
                </li>
            </ul>
        </div>
        <PaginationButton
            v-show="!shouldHideButtons"
            :direction="'backward'"
            :label="`${$t('global__next')} - ${name}`"
            :isDisabled="disableBackButton"
            :class="{
                'c-carousel__button': true,
                'c-carousel__button--left': !hasIncreasedPageButtonSpacing,
                'c-carousel__button--left-far': hasIncreasedPageButtonSpacing,
                'c-carousel__button--hover-only': onlyShowPaginationOnHover,
            }"
            :isTranslucent="isTranslucent"
            :isLight="lightButtons"
            @click="changePage(DIRECTION_PREVIOUS)"
            @keyup.left="scrollToPrev"
            @keyup.right="scrollToNext"
        />
        <PaginationButton
            v-show="!shouldHideButtons"
            :direction="'forward'"
            :label="`${$t('global__previous')} - ${name}`"
            :isDisabled="disableNextButton"
            :class="{
                'c-carousel__button': true,
                'c-carousel__button--right': !hasIncreasedPageButtonSpacing,
                'c-carousel__button--right-far': hasIncreasedPageButtonSpacing,
                'c-carousel__button--hover-only': onlyShowPaginationOnHover,
            }"
            :isTranslucent="isTranslucent"
            :isLight="lightButtons"
            @click="changePage(DIRECTION_NEXT)"
            @keyup.left="scrollToPrev"
            @keyup.right="scrollToNext"
        />
        <div v-if="shouldShowCarouselPagination && isMounted" class="c-carousel__slide-marker-container">
            <span
                v-for="(_, index) in pageCount"
                :key="`carousel-${name}-marker-${index}`"
                class="c-carousel__slide-marker"
                @click="changePage(null, index * pageSize)"
            >
                <span
                    class="c-carousel__slide-marker-dot"
                    :class="{ 'c-carousel__slide-marker-dot--current': pageIndex === index }"
                ></span>
            </span>
        </div>
    </div>
</template>

<script>
import { uniqueId } from 'lodash-es';
import { mapActions, mapState } from 'vuex';

import {
    DIRECTION_NEXT,
    DIRECTION_PREVIOUS,
    NEXT,
    PREVIOUS,
} from '~coreModules/core/js/constants';
import { PAGINATION_CLICK } from '~coreModules/core/js/global-event-constants';

import { getCarouselSlotName } from '~coreModules/core/js/carousel-utils';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { BROWSER_MODULE_NAME } from '~coreModules/browser/js/browser-store';

import PaginationButton from '~coreModules/core/components/ui/buttons/PaginationButton.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Carousel',
    components: {
        PaginationButton,
    },
    props: {
        useVariableWidth: {
            type: Boolean,
            default: false,
        },
        analyticsType: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        carouselItems: {
            type: Array,
            required: true,
        },
        noContainerMargin: {
            type: Boolean,
            default: false,
        },
        noSlidePadding: {
            type: Boolean,
            default: false,
        },
        onlyShowPaginationOnHover: {
            type: Boolean,
            default: false,
        },
        defaultSlide: {
            type: Number,
            default: 0,
        },
        translucentButtons: {
            type: Boolean,
            default: false,
        },
        lightButtons: {
            type: Boolean,
            default: false,
        },
        hideButtons: {
            type: Boolean,
            default: false,
        },
        hasIncreasedPageButtonSpacing: {
            type: Boolean,
            default: false,
        },
        slideClass: {
            type: String,
            default: '',
        },
        containerPaddingSize: {
            type: String,
            default: 'large',
            validator(dir) {
                return ['small', 'large'].indexOf(dir) !== -1;
            },
        },
        catalogResponseId: {
            type: String,
            default: null,
        },
        resetOnResize: {
            type: Boolean,
            default: false,
        },
        wrappingLink: {
            type: [String, Object],
            default: '',
        },
        // utilize this if the carousel items are not static
        // i.e. closeted products tray - an item can be removed
        carouselItemUniqueKey: {
            type: String,
            default: null,
        },
        hasLargeSlidePadding: {
            type: Boolean,
            default: false,
        },
        carouselPageIndex: {
            type: Number,
            default: null,
        },
        onScrollComplete: {
            type: Function,
            default: () => {},
        },
        // FIXED WIDTH ITEM LOGIC ONLY
        desktopSlideSize: {
            type: Number,
            default: 5,
        },
        mediumSlideSize: {
            type: Number,
            default: 3,
        },
        mobileSlideSize: {
            type: Number,
            default: 2,
        },
        mobilePeek: {
            type: Boolean,
            default: true,
        },
        mobilePeekPercent: {
            type: Number,
            default: 15,
        },
        mediumPeekPercent: {
            type: Number,
            default: 15,
        },
        desktopPeekPercent: {
            type: Number,
            default: 0,
        },
        displayCarouselPagination: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['carousel-moved', 'click'],
    data() {
        return {
            isMounted: false,
            DIRECTION_NEXT,
            DIRECTION_PREVIOUS,
            isScrolling: false,
            isSwiping: false,
            lastScrollDirection: DIRECTION_NEXT,
            numberOfItemsInView: 0,
            scrollAnimationOptions: {
                container: undefined,
                easing: 'ease-in-out',
                force: true,
                x: true,
                y: false,
            },
            scrollDebounce: 100,
            scrollLeft: 0,
            scrollContainer: null,
            scrollContainerId: undefined,
            scrollDuration: 400,
            scrollTimer: null,
            isUsingPaginationButtons: false,
            noRightPadding: false,
            noLeftPadding: false,
            slideRefs: [],
            getCarouselSlotName,
            unwatchPageIndex: null,
        };
    },
    computed: {
        ...mapState(BROWSER_MODULE_NAME, [
            'isResizing',
        ]),

        // BEGIN FIXED WIDTH ONLY
        pageSize() {
            if (this.useVariableWidth) return null;

            if (this.$mediaQueries.isSmallish) {
                return this.mobileSlideSize;
            }

            if (this.$mediaQueries.isMedium) {
                return this.mediumSlideSize;
            }

            return this.desktopSlideSize;
        },
        pageCount() {
            if (this.useVariableWidth) return null;

            return Math.ceil(this.carouselItems.length / this.pageSize);
        },
        pageIndex() {
            if (this.useVariableWidth) return null;

            if (this.numberOfItemsInView === this.carouselItems.length) {
                return this.pageCount - 1;
            }
            return Math.max(0, Math.floor(this.numberOfItemsInView / this.pageSize) - 1);
        },
        carouselItemWidthPercentage() {
            if (this.useVariableWidth) return null;

            const gapOffsetPerSlide = ((this.pageSize - 1) * this.slideGap) / this.pageSize;

            if (this.shouldCarouselPeek && this.pageCount > 1) {
                const { mobilePeekPercent, mediumPeekPercent, desktopPeekPercent, $mediaQueries } = this;
                // eslint-disable-next-line no-nested-ternary
                const peekPercentage = $mediaQueries.isLargish ? desktopPeekPercent :
                    ($mediaQueries.isMedium ? mediumPeekPercent : mobilePeekPercent);

                return `calc(${100 / (this.pageSize + (peekPercentage * 0.01))}% - ${gapOffsetPerSlide}px)`;
            }

            return `calc(${100 / this.pageSize}% - ${gapOffsetPerSlide}px)`;
        },
        shouldShowCarouselPagination() {
            return this.displayCarouselPagination && this.isMounted && !this.isOnlyOnePage && !this.useVariableWidth;
        },

        // END FIXED WIDTH ONLY

        isTranslucent() {
            return this.translucentButtons || (this.isMounted && !this.$mediaQueries.isLargish);
        },
        isOnlyOnePage() {
            if (this.scrollContainer && this.numberOfItemsInView > -1) {
                if (!this.useVariableWidth) {
                    return this.pageCount <= 1;
                }

                const { scrollContainer: { scrollWidth, offsetWidth } } = this;
                return scrollWidth <= offsetWidth;
            }
            return true;
        },
        disableBackButton() {
            if (this.scrollContainer) {
                if (!this.useVariableWidth) {
                    return this.numberOfItemsInView === this.pageSize;
                }

                return this.scrollLeft === 0;
            }
            return true;
        },
        disableNextButton() {
            if (this.scrollContainer && this.numberOfItemsInView > -1) {
                if (!this.useVariableWidth) {
                    return this.numberOfItemsInView === this.carouselItems.length;
                }

                const { scrollContainer: { offsetWidth, scrollWidth } } = this;
                return this.scrollLeft + offsetWidth >= scrollWidth;
            }
            return true;
        },
        shouldMobilePeek() {
            return !this.$mediaQueries.isLargish && this.mobilePeek &&
                    this.mobilePeekPercent > 0;
        },
        shouldDesktopPeek() {
            return this.$mediaQueries.isLargish && this.desktopPeekPercent > 0;
        },
        shouldMediumPeek() {
            return this.$mediaQueries.isMedium && this.mediumPeekPercent > 0;
        },
        shouldCarouselPeek() {
            return this.shouldMobilePeek || this.shouldDesktopPeek || this.shouldMediumPeek;
        },
        shouldHideButtons() {
            return this.hideButtons || this.isOnlyOnePage;
        },
        scrollSnapStop() {
            return 'normal';
        },
        slideGap() {
            if (this.noSlidePadding) {
                return 0;
            }

            if (this.hasLargeSlidePadding) {
                return 16;
            }

            return 8;
        },
        slideGapPx() {
            return `${this.slideGap}px`;
        },
    },
    watch: {
        isResizing(isResizing, wasResizing) {
            if (wasResizing && !isResizing && this.resetOnResize) {
                this.resetCarousel();
                this.setNumberOfItemsInView();
            }
        },
        isScrolling(isScrolling, wasScrolling) {
            const { scrollContainer: { scrollLeft } } = this;

            if (isScrolling && !this.isUsingPaginationButtons) {
                this.isSwiping = true;

                if (scrollLeft > this.scrollLeft) {
                    this.lastScrollDirection = DIRECTION_NEXT;
                } else {
                    this.lastScrollDirection = DIRECTION_PREVIOUS;
                }

                this.scrollLeft = scrollLeft;
            }

            if (!isScrolling && wasScrolling) {
                this.scrollLeft = scrollLeft;
                this.isUsingPaginationButtons = false;
                this.setNumberOfItemsInView();
            }
        },
        /* navigate backwards if the slide being viewed has products removed, and current slide becomes empty */
        'carouselItems.length': {
            async handler() {
                await this.$nextTick();

                if (this.scrollContainer) {
                    const { scrollContainer: { scrollLeft } } = this;
                    const firstViewableItemIndex = this.slideRefs
                        .findIndex(item => item?.offsetLeft >= scrollLeft) || 0;

                    if (firstViewableItemIndex > -1) {
                        this.changePage(DIRECTION_PREVIOUS);
                    }
                }
            },
        },
        noSlidePadding() {
            this.$nextTick(() => {
                this.resetCarousel();
            });
        },
        numberOfItemsInView(newCount) {
            this.$emit('carousel-moved', {
                numberOfItemsInView: newCount,
            });

            if (newCount > 0) {
                this.trackGlobalEvent({
                    type: PAGINATION_CLICK,
                    data: {
                        action: 'carousel-scroll',
                        label: this.lastScrollDirection === DIRECTION_NEXT ? NEXT : PREVIOUS,
                        value: newCount,
                        contentModuleId: this.name,
                        catalogResponseId: this.catalogResponseId,
                        analyticsType: this.analyticsType,
                    },
                });
            }

        },
        carouselPageIndex(newIndex, oldIndex) {
            if (typeof newIndex === 'number' && newIndex !== oldIndex) {
                this.changePage(null, newIndex);
            }
        },
        carouselItemWidthPercentage() {
            if (!this.useVariableWidth) {
                this.$nextTick(() => {
                    this.setMobilePadding();
                });
            }
        },
    },
    created() {
        if (this.onScrollComplete) {
            this.scrollAnimationOptions.onDone = this.onScrollComplete;
        }
    },
    mounted() {
        this.isMounted = true;
        const uuid = uniqueId();
        this.scrollContainerId = `scroll-container-${uuid}`;
        this.scrollAnimationOptions.container = `#${this.scrollContainerId}`;

        this.$nextTick(() => {
            if (this.$refs.scrollContainer) {
                this.scrollContainer = this.$refs.scrollContainer;
                this.scrollContainer.addEventListener('scroll', this.scrollHandler);

                if (this.defaultSlide > 0) {
                    this.setScrollPosition(null, this.defaultSlide);
                } else {
                    this.setNumberOfItemsInView();
                }

                if (this.$mediaQueries.isSmallish) {
                    this.isSwiping = true;
                }

                this.setMobilePadding();
            }
        });
    },
    beforeUpdate() {
        this.slideRefs = [];
    },
    unmounted() {
        if (this.scrollContainer) {
            this.scrollContainer.removeEventListener('scroll', this.scrollHandler);
        }
        this.unwatchPageIndex?.();
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        resetCarousel() {
            if (this.scrollContainer) {
                this.setScrollPosition(null, 0, false);
            }
        },
        getNumberOfItemsInView() {
            let numItemsInView = 0;
            if (this.scrollContainer) {
                const { scrollContainer: { scrollLeft, offsetWidth } } = this;
                const scrollRight = Math.trunc(scrollLeft + offsetWidth);

                this.slideRefs.some((item, index) => {
                    const itemWidth = item?.getBoundingClientRect().width;
                    const offsetLeft = item?.offsetLeft;
                    const offsetRight = Math.trunc(offsetLeft + itemWidth);

                    if (offsetRight <= scrollRight) {
                        numItemsInView = index + 1;
                        return false;
                    }
                    return true;
                });
            }

            return numItemsInView;
        },
        setNumberOfItemsInView() {
            this.numberOfItemsInView = Math.floor(this.getNumberOfItemsInView());
        },
        getScrollToElementIndex(direction, explicitItemIndex) {
            let scrollToElementIndex = explicitItemIndex;
            const isValueNaN = value => Number.isNaN(parseInt(value, 10));

            if (isValueNaN(explicitItemIndex)) {
                const { scrollContainer: { scrollLeft, offsetWidth } } = this;
                let scrollRight;

                if (direction === DIRECTION_PREVIOUS) {
                    const gapOffset = this.slideGap || 0;
                    const firstViewableItemIndex = this.slideRefs
                        .findIndex(item => Math.trunc(item?.offsetLeft) >= Math.trunc(scrollLeft)) || 0;

                    const previousScrollLeft = Math
                        .trunc(this.slideRefs?.[firstViewableItemIndex]?.offsetLeft - offsetWidth - gapOffset);

                    scrollToElementIndex = this.slideRefs
                        .findIndex(item => Math.trunc(item?.offsetLeft) >= previousScrollLeft) || 0;
                } else {
                    scrollRight = Math.trunc(scrollLeft + offsetWidth);

                    this.slideRefs.some((item, index) => {
                        const itemWidth = item?.getBoundingClientRect().width;
                        const offsetLeft = item?.offsetLeft;
                        const offsetRight = Math.trunc(offsetLeft + itemWidth);
                        scrollToElementIndex = index;

                        if (offsetRight <= scrollRight) {
                            return false;
                        }
                        return true;
                    });
                }
            }

            return scrollToElementIndex;
        },
        setScrollPosition(direction, explicitItemIndex, shouldAnimate = true) {
            if (this.scrollContainer) {
                const scrollToElementIndex = this.getScrollToElementIndex(direction, explicitItemIndex);
                const { scrollContainer: { scrollLeft } } = this;

                if (!(scrollToElementIndex === 0 && scrollLeft === 0)) {
                    if (shouldAnimate) {
                        this.$scrollTo(
                            this.slideRefs[scrollToElementIndex],
                            this.scrollDuration,
                            this.scrollAnimationOptions,
                        );
                    } else {
                        const scrollToElement = this.slideRefs?.[scrollToElementIndex];

                        if (scrollToElement) {
                            this.scrollContainer.scrollLeft = scrollToElement.offsetLeft;
                        } else {
                            // eslint-disable-next-line max-len
                            this.$logger.debugError('no element was found at the desired index, preventing carousel navigation');
                        }
                    }
                }
            }
        },
        setMobilePadding() {
            if (this.isOnlyOnePage) {
                if (this.shouldCarouselPeek) {
                    this.noRightPadding = false;
                    this.noLeftPadding = false;
                } else {
                    this.noRightPadding = true;
                    this.noLeftPadding = true;
                }
                return;
            }

            if (!this.noSlidePadding && !this.$mediaQueries.isLargish) {
                const numberOfItemsInView = this.getNumberOfItemsInView();
                const isNearEnd = numberOfItemsInView > (this.carouselItems.length - 0.5);

                this.noRightPadding = !isNearEnd;
                this.noLeftPadding = isNearEnd;
            }
        },
        scrollHandler() {
            window.requestAnimationFrame(() => {
                clearTimeout(this.scrollTimer);

                if (!this.isScrolling) {
                    this.isScrolling = true;
                }

                this.scrollTimer = setTimeout(() => {
                    this.isScrolling = false;
                }, this.scrollDebounce);
                this.$nextTick(() => {
                    this.setMobilePadding();
                });
            });
        },
        changePage(direction, explicitItemIndex) {
            if (!this.isScrolling) {
                this.isUsingPaginationButtons = true;
                this.isSwiping = false;
                this.lastScrollDirection = direction;
                this.setScrollPosition(direction, explicitItemIndex);
            }
        },
        scrollToPrev(e) {
            e?.stopImmediatePropagation();
            this.changePage(DIRECTION_PREVIOUS);
        },
        scrollToNext(e) {
            e?.stopImmediatePropagation();
            this.changePage(DIRECTION_NEXT);
        },
        setSlideRef(el) {
            if (el) {
                this.slideRefs.push(el);
            }
        },
        handleLinkClick(e) {
            this.$emit('click', e);
            if (this.wrappingLink) {
                this.$router.push(this.wrappingLink);
            }
        },
    },
};
</script>

<style lang="scss">
    .c-carousel {
        $this: &;
        position: relative;
        margin: 0 $nu-spacer-2;
        transition: margin 0.3s ease;

        @include breakpoint(large) {
            margin: 0;
        }

        &--no-padding {
            margin: 0;
        }

        &--small-padding {
            margin: 0 $nu-spacer-1;
        }

        &--no-left-padding {
            margin-left: 0;
        }

        &--no-right-padding {
            margin-right: 0;
        }

        &:focus-within,
        &:hover {
            #{$this}__button {
                opacity: 1;
            }
        }

        &__container {
            white-space: nowrap;
            display: block;
            outline: none;

            &.focus-visible:focus[data-focus-visible-added] {
                outline: none;
            }

            &:not(&--no-margin) {
                @include breakpoint(large) {
                    margin: 0 $nu-spacer-12;
                }
            }

            &--link {
                cursor: pointer;
            }
        }

        &__slide-container {
            display: flex;
            overflow-y: hidden;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
            transform: matrix(1, 0, 0, 1, 0, 0);
            scroll-snap-type: x mandatory;
            width: 100%;
            line-height: 0;
            transition-duration: 400ms;
            transition-timing-function: ease;
            padding: 5px 0;
            margin: -5px 0;
            scrollbar-width: none;
            gap: v-bind(slideGapPx);

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &__slide {
            display: inline-block;
            line-height: normal;
            flex: 0 0 auto;

            &--snap-start {
                scroll-snap-align: start;
            }

            &--snap-start:last-child {
                scroll-snap-align: end;
            }

            scroll-snap-stop: v-bind(scrollSnapStop);

            &--fixed-width {
                width: v-bind(carouselItemWidthPercentage);
            }
        }

        &__button {
            position: absolute;
            top: 0;
            bottom:0;
            margin: auto;

            @include touch-device {
                display: none;

                @include breakpoint(large) {
                    display: flex;
                }
            }

            @include non-touch-device {
                transition: opacity 0.2s ease;
                opacity: 0;

                @include breakpoint(large) {
                    opacity: 1;

                    &--hover-only {
                        opacity: 0;
                    }
                }

            }

            &--left {
                left: $nu-spacer-1;

                @include non-touch-device {
                    margin-left: $nu-spacer-3;

                    @include breakpoint(small) {
                        margin-left: $nu-spacer-2;
                    }
                }
            }

            &--left-far {
                left: $nu-spacer-4;
            }

            &--right {
                right: $nu-spacer-1;

                @include non-touch-device {
                    margin-right: $nu-spacer-3;

                    @include breakpoint(large) {
                        margin-right: $nu-spacer-2;
                    }
                }
            }

            &--right-far {
                right: $nu-spacer-4;
            }
        }

        &__slide-marker-container {
            align-items: center;
            margin-top: $nu-spacer-3;
            display: flex;
            justify-content: center;
            width: 100%;
        }

        &__slide-marker {
            padding: $nu-spacer-0pt5;
            margin-right: $nu-spacer-0pt5;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }
        }

        &__slide-marker-dot {
            display: block;
            border-radius: 100%;
            border: 1px solid $nu-primary;
            height: 8px;
            width: 8px;
            transition: background-color 0.2s ease;

            &--current {
                background-color: $nu-primary;
            }
        }
    }
</style>
