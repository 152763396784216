<template>
    <BaseHomepageContainer
        :layout="RawLayout"
        :contentfulContentBlock="ContentfulContentBlock"
        :anonymousHomepage="commonHomepageV2 ? AnonymousHomepageV2 : AnonymousHomepage"
    />
</template>

<!-- Seperate script tag needed for custom fetchData and vuex logic, to be used in entry-server and entry-client -->
<script>
import homepageModule, { HOMEPAGE_MODULE_NAME, FETCH_HOMEPAGE_CONTENT } from '~coreRoutes/homepage/js/homepage-store';
import { UNIVERSAL_REDIRECT } from '~coreModules/core/js/store';

import { CONTENT_SLUGS, CONTENT_TYPES } from '~modules/contentful/js/contentful-constants';
import { SITE_SETTINGS_MODULE_NAME } from '~modules/site-settings/js/site-settings-store';

const vuex = {
    moduleName: HOMEPAGE_MODULE_NAME,
    module: homepageModule,
};

async function fetchData({ router, store }) {
    const { getters } = store;
    const { loggedIn } = getters;

    if (loggedIn) {
        return store.dispatch(UNIVERSAL_REDIRECT, {
            router,
            route: '/rent',
        });
    }
    // NU_FEATURE: COMMON_HOMEPAGE_V2
    // https://urbnit.atlassian.net/browse/TYP-26324
    const slug = store.getters[`${SITE_SETTINGS_MODULE_NAME}/commonHomepageV2`] ?
        CONTENT_SLUGS.HOMEPAGE_SLUG_V2 : CONTENT_SLUGS.HOMEPAGE_SLUG;

    return store.dispatch(`${HOMEPAGE_MODULE_NAME}/${FETCH_HOMEPAGE_CONTENT}`, {
        slug,
        contentType: CONTENT_TYPES.CUSTOM_CONTENT_PAGE,
    });
}

export default {
    vuex,
    fetchData,
};
</script>

<script setup>
/* eslint-disable import/first */
import { defineAsyncComponent, markRaw } from 'vue';

import { useGetters } from '~coreModules/core/js/composables/vuexHelpers';

import useHomepageJsonLd from '~routes/homepage/js/composables/use-homepage-json-ld.js';
import metaTagsMixin from '~modules/core/js/seo/meta-tags';

import BaseHomepageContainer from '~coreRoutes/homepage/components/BaseHomepageContainer.vue';
import Layout from '~modules/core/components/layouts/Layout.vue';
/* eslint-enable import/first */

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable no-undef */
defineOptions({
    mixins: [
        metaTagsMixin,
    ],
});

useHomepageJsonLd();

// NU_FEATURE: COMMON_HOMEPAGE_V2
// https://urbnit.atlassian.net/browse/TYP-26324
// remove getter
const { commonHomepageV2 } = useGetters(SITE_SETTINGS_MODULE_NAME, ['commonHomepageV2']);

const RawLayout = markRaw(Layout);

const AnonymousHomepage = markRaw(
    defineAsyncComponent(
        () => import(
            /* webpackChunkName: "commonAnonymousHomepage" */
            '~routes/homepage/components/CommonAnonymousHomepage.vue'),
    ));

// NU_FEATURE: COMMON_HOMEPAGE_V2
// https://urbnit.atlassian.net/browse/TYP-26324
// remove component
const AnonymousHomepageV2 = markRaw(
    defineAsyncComponent(
        () => import(
            /* webpackChunkName: "commonAnonymousHomepageV2" */
            '~routes/homepage/components/CommonAnonymousHomepageV2.vue'),
    ));

const ContentfulContentBlock = markRaw(
    defineAsyncComponent(
        () => import(
            /* webpackChunkName: "contentful-content-block" */
            '~modules/contentful/components/ContentfulContentBlock.vue'),
    ),
);

</script>

<style lang="scss">
</style>
