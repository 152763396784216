<template>
    <div :class="['c-app', isScrollLocked && 'is-scroll-locked' ]">
        <noscript>
            Your browser does not have JS enabled, you are still able to browse
            the website but you wont be able to access advanced features such
            as editing or logging in.
        </noscript>

        <slot name="aboveContainer"></slot>

        <template v-if="shouldRenderContents">
            <div
                ref="contents"
                :tabindex="modalIsOpen ? '-1' : undefined"
                :aria-hidden="modalIsOpen ? 'true' : undefined"
                :style="modalScrollOffsetStyles"
                class="c-app__container"
            >
                <template v-if="!isMobileWebview">
                    <AccessibilityJumpLinks
                        :class="{
                            'c-app__jump-links': true,
                            'c-app__jump-links--focused': isJumpLinkFocused
                        }"
                        @focus="jumpLinkFocused"
                        @unfocus="jumpLinkFocused"
                    />
                    <template v-if="!ignoreBaseLayout">
                        <slot name="header"></slot>
                    </template>
                </template>

                <RouteContentLoader
                    class="c-app__main-content"
                    :ignoreBaseLayout="ignoreBaseLayout"
                    :routeIsLoading="routeIsLoading"
                    @content-is-done-rendering="contentIsDoneRendering"
                />

                <div v-if="renderFooter" class="c-app__footer-container">
                    <slot name="footer"></slot>
                </div>
            </div>

            <UrbnModalSingleton
                :contentEl="$refs.contents"
                :scrollTopWithoutHeader="scrollTopWithoutHeader"
            />

            <slot name="belowContainerContents"></slot>
        </template>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import {
    debounce,
    isEqual,
    throttle,
} from 'lodash-es';

import { useState, useGetters, useMutations, useActions } from '~coreModules/core/js/composables/vuexHelpers';
import useJsonLd from '~coreModules/core/js/seo/composables/use-json-ld';

import { DEBOUNCE_DELAY } from '~coreModules/core/js/constants';
import { PAGEVIEW } from '~coreModules/core/js/global-event-constants';
import { getModalHashEventWithProps } from '~coreModules/modals/js/modal-utils';
import { GLOBAL_EVENT, SET_ROUTE_IS_LOADING } from '~coreModules/core/js/store';
import {
    BROWSER_MODULE_NAME,
    GET_SCROLL_INFORMATION,
    GET_CLIENT_DIMENSIONS,
    SET_BROWSER_INFO,
} from '~coreModules/browser/js/browser-store';
import { MODALS_MODULE_NAME, HANDLE_MODAL_HASH_EVENT, POP_MODALS } from '~coreModules/modals/js/modals-store';

import runtimeConfig from '~config/config';

// Load this before importing any components, this ensures that all of our global
// classes come first in the generated app.css file, followed by
// any component-specific classes
import '~coreScss/global/global-styles.scss';

import RouteContentLoader from '~coreModules/core/components/RouteContentLoader.vue';
import AccessibilityJumpLinks from '~coreModules/core/components/AccessibilityJumpLinks.vue';
import UrbnModalSingleton from '~coreModules/modals/components/UrbnModalSingleton.vue';

const props = defineProps({
    shouldRenderContents: {
        type: Boolean,
        default: true,
    },
    contentIsDoneRendering: {
        type: Function,
        default: null,
    },
    additionalIsScrollLockedCondition: {
        type: Boolean,
        default: false,
    },
});

const route = useRoute();
useJsonLd();

const { routeIsLoading } = useState(['routeIsLoading']);
const { scrollTopWithoutHeader } = useState(BROWSER_MODULE_NAME, [
    'scrollTopWithoutHeader',
]);
const { omitPageviewEvent } = useState(MODALS_MODULE_NAME, [
    'omitPageviewEvent',
]);

const { isMobileWebview } = useGetters(['isMobileWebview']);
const { modalIsOpen, activeModal } = useGetters(MODALS_MODULE_NAME, {
    modalIsOpen: 'isOpen',
    activeModal: 'activeModal',
});

const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });
const { getScrollInformation, getClientDimensions } = useActions(BROWSER_MODULE_NAME, {
    getScrollInformation: GET_SCROLL_INFORMATION,
    getClientDimensions: GET_CLIENT_DIMENSIONS,
});
const { handleModalHashEvent, popModals } = useActions(MODALS_MODULE_NAME, {
    handleModalHashEvent: HANDLE_MODAL_HASH_EVENT,
    popModals: POP_MODALS,
});

const { setRouteIsLoading } = useMutations({ setRouteIsLoading: SET_ROUTE_IS_LOADING });
const { setBrowserInfo } = useMutations(BROWSER_MODULE_NAME, { setBrowserInfo: SET_BROWSER_INFO });

const boundResizeListener = ref(null);
const boundScrollListener = ref(null);
const modalScrollOffsetStyles = ref({});
const isJumpLinkFocused = ref(false);

const ignoreBaseLayout = computed(() => route.meta.ignoreBaseLayout);
const isScrollLocked = computed(() => modalIsOpen.value || props.additionalIsScrollLockedCondition);
const renderFooter = computed(() => !ignoreBaseLayout.value && !isMobileWebview.value);

function getModalRoute() {
    if (activeModal.value) {
        const { path, analytics } = activeModal.value;
        let modalPath = path;

        if (!/^\//.test(modalPath)) {
            const { path: routePath } = route;
            modalPath = `${routePath}/${modalPath}`;
        }

        return {
            path: modalPath,
            meta: { analytics },
        };
    }
    return null;
}

function bindScrollListener() {
    if (process.env.VUE_ENV === 'client' && !boundScrollListener.value) {
        boundScrollListener.value = throttle(getScrollInformation, 30);
        window.addEventListener('scroll', boundScrollListener.value);
        getScrollInformation();
    }
}

function unbindScrollListener() {
    if (boundScrollListener.value) {
        window.removeEventListener('scroll', boundScrollListener.value);
        boundScrollListener.value = null;
    }
}

function setRootVh() {
    const documentStyle = document.documentElement.style;
    const vh = window.innerHeight;

    documentStyle.setProperty('--vh100', `${vh}px`);
    documentStyle.setProperty('--vh', `${vh * 0.01}px`);
}

function bindResizeListener() {
    if (process.env.VUE_ENV === 'client' && !boundResizeListener.value) {
        boundResizeListener.value = debounce(
            () => {
                getClientDimensions();
                setRootVh();
            },
            DEBOUNCE_DELAY,
        );
        window.addEventListener('resize', boundResizeListener.value);
        getClientDimensions(true);
        setRootVh();
    }
}

function unbindResizeListener() {
    if (boundResizeListener.value) {
        window.removeEventListener('resize', boundResizeListener.value);
        boundResizeListener.value = null;
    }
}

function handleModalFromHash() {
    const modalHashEvent = getModalHashEventWithProps(route, runtimeConfig.features);

    if (modalHashEvent) {
        handleModalHashEvent(modalHashEvent);
    }
}

function jumpLinkFocused(isFocused) {
    isJumpLinkFocused.value = isFocused;
}

watch(() => ({
    path: route.path,
    query: route.query,
    hash: route.hash,
}), (to, from) => {
    const toPath = to?.path?.split('?')?.[0];
    const toQuery = to?.query;
    const toHash = to?.hash;
    const fromPath = from?.path?.split('?')?.[0];
    const fromQuery = from?.query;
    const fromHash = from?.hash;

    // pop modals if the route path is changing
    if (modalIsOpen.value && toPath !== fromPath) {
        popModals({ omitPageviewEvent: true });
    }

    // if path and query are equal, it was a hash change
    if ((isEqual(toPath, fromPath) && isEqual(toQuery, fromQuery)) || (toHash && fromHash !== toHash)) {
        handleModalFromHash();
    }

}, { deep: true });

watch(isScrollLocked, (isNowScrollLocked) => {
    if (isNowScrollLocked && scrollTopWithoutHeader.value) {
        modalScrollOffsetStyles.value = {
            top: `-${scrollTopWithoutHeader.value}px`,
            position: 'relative',
        };
    } else if (!isNowScrollLocked) {
        modalScrollOffsetStyles.value = {};
    }

    if (process.env.VUE_ENV === 'client') {
        if (isNowScrollLocked) {
            document.getElementsByTagName('html')[0].classList.add('scroll-lock');
        } else {
            document.getElementsByTagName('html')[0].classList.remove('scroll-lock');
        }
    }
}, { immediate: true });

/* Emit PAGE_VIEW events for modals */
watch(activeModal, (newModal, oldModal) => {
    if (!isEqual(newModal, oldModal) && !omitPageviewEvent.value) {
        const parents = route.matched.map(parent => ({ ...parent }));
        let currentPage = getModalRoute();

        if (!currentPage) {
            currentPage = route;
            parents.pop();
        }

        trackGlobalEvent({
            type: PAGEVIEW,
            data: {
                currentPage,
                parents,
            },
        });
    }
});

onMounted(() => {
    bindScrollListener();
    bindResizeListener();

    /* eslint-disable-next-line global-require */
    require('focus-visible/dist/focus-visible.min.js');

    setRouteIsLoading(false);

    // Load any direct route hash-modals
    handleModalFromHash();
});

onUnmounted(() => {
    unbindScrollListener();
    unbindResizeListener();
});

if (process.env.VUE_ENV === 'client') {
    setBrowserInfo();
}

</script>

<style lang="scss">
    @include js-breakpoint-support();

    html.scroll-lock {
        &, body {
            height: var(--vh100);
            overflow: hidden;
            box-sizing: border-box;
        }
    }

    .c-app {
        &.is-scroll-locked {
            position:fixed;
            height: var(--vh100);
            overflow: hidden;
            left: 0;
            right: 0;
        }

        &__container {
            $this: &;

            display: flex;
            flex-direction: column;
            min-height: var(--vh100);

            &__jump-links {
                &--focused ~ #{$this}__header,
                &--focused + #{$this}__main-content .c-slide-layout__header {
                    top: $nu-spacer-3;
                }
            }
        }
    }
</style>
