const getRuntimeConfig = require('@nuuly-ui/core-lib/config/config');

// This config module is the main location for application-level configuration.
// Because this module gathers a lot of it's information from environment variables
// and information only known on the server, we export it and render it into a
// meta tag in the initial HTML render and when accessing this module client
// side, we parse it from the meta tag.  This allows us to avoid any node
// polyfills in our client code and avoids needing to specify EnvironmentPlugin
// values for he vast majority of our environment variables in our webpack
// builds

const commonConfig = {
    // Urls for API calls
    apiUrls: {
        catalogService: process.env.NUU_CATALOG_SERVICE_API_URL,
    },
};

const runtimeConfig = getRuntimeConfig(commonConfig);

module.exports = runtimeConfig;
