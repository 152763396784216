/* eslint-disable import/prefer-default-export */
import { isEmpty, pick } from 'lodash-es';

import runtimeConfig from '~config/config';

import { RECOMMENDATION_TRAY_PAGE_SIZE } from '~modules/catalog/js/catalog-constants';

const { apiUrls } = runtimeConfig;

export function getCatalogItems({
    r15Svc,
    catalogSlug,
    query = {},
    catalogFilterWhitelist = [],
    logger,
}) {
    if (isEmpty(catalogFilterWhitelist)) {
        logger.error('No catalogFilterWhitelist passed to GET getCatalogItems');
    }
    const whitelistedQuery = pick(query, catalogFilterWhitelist);
    const options = {
        params: {
            ...whitelistedQuery,
            pageNumber: whitelistedQuery.pageNumber || 1,
            itemsPerPage: whitelistedQuery.itemsPerPage || RECOMMENDATION_TRAY_PAGE_SIZE,
        },
    };

    return r15Svc.get(`${apiUrls.catalogService}/${catalogSlug}`, options);
}
