<template>
    <Component
        :is="layout"
        class="c-homepage"
        :flushWithHeader="true"
        :noBottomPadding="!loggedIn"
    >
        <slot></slot>
        <template v-if="loggedIn">
            <Component
                :is="contentfulContentBlock"
                :contentRows="authenticatedHomepageContent"
                :contentTitle="homepageContent.title"
            />
        </template>
        <Component
            :is="anonymousHomepage"
            v-else
            :content="anonymousHomepageContent"
            :contentTitle="homepageContent.title"
        />
    </Component>
</template>

<script setup>
import { computed } from 'vue';

import { useGetters, useState } from '~coreModules/core/js/composables/vuexHelpers';

import useAnalyticsPageView from '~coreModules/analytics/js/composables/use-analytics-pageview';

import homepageMetaMixin from '~coreRoutes/homepage/js/homepage-meta';

import { HOMEPAGE_MODULE_NAME } from '~coreRoutes/homepage/js/homepage-store';

defineProps({
    layout: {
        type: [Function, Object],
        required: true,
    },
    anonymousHomepage: {
        type: [Function, Object],
        required: true,
    },
    contentfulContentBlock: {
        type: [Function, Object],
        required: true,
    },
});

// NU_TODO: https://urbnit.atlassian.net/browse/TYP-26682
// Remove below when we update to vue-eslint-parser v9.0.0 or later
/* eslint-disable no-undef */
defineOptions({
    mixins: [
        homepageMetaMixin,
    ],
});

useAnalyticsPageView();

const { loggedIn } = useGetters(['loggedIn']);

const { homepageContent } = useState(HOMEPAGE_MODULE_NAME, {
    homepageContent: 'contentPage',
});

const authenticatedHomepageContent = computed(() => homepageContent?.value?.rows);
const anonymousHomepageContent = computed(() => homepageContent?.value?.content);

</script>
