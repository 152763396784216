<i18n>
[
    "global__supportEmailAddress--escaped"
]
</i18n>

<template>
    <div :class="containerClasses">
        <template v-for="button in buttons.destinations">
            <div
                v-if="button.shouldOpenChat"
                :key="`intercom-link-${button.linkPath}`"
                class="c-cta-buttons__button-container"
                @click="openIntercom"
            >
                <BaseButton
                    :colorVariant="colorVariant"
                    :size="buttons.buttonSize"
                    :variant="buttonVariant"
                    class="c-cta-buttons__button"
                    :class="{
                        [`c-cta-buttons__button--${buttonVariant}-${colorVariant}`]: true,
                    }"
                >
                    {{ button.linkTitle }}
                </BaseButton>
            </div>
            <div
                v-else
                :key="button.linkPath"
                class="c-cta-buttons__button-container"
            >
                <BaseLink
                    :to="button.linkPath"
                    :target="button.openInNewWindow ? '_blank' : '_self'"
                    :isButton="true"
                    :colorVariant="colorVariant"
                    :size="buttons.buttonSize"
                    :variant="buttonVariant"
                    class="c-cta-buttons__button"
                    @click="handleButtonClick"
                >
                    {{ button.linkTitle }}
                </BaseLink>
            </div>
        </template>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useGetters, useActions } from '~coreModules/core/js/composables/vuexHelpers';

import { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';
import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { CTA_BUTTON_CLICKED } from '~coreModules/core/js/global-event-constants';

import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

const props = defineProps({
    buttons: {
        type: Object,
        default: null,
    },
    isAbsoluteCentered: {
        type: Boolean,
        default: false,
    },
    numberOfModules: {
        type: Number,
        default: null,
    },
});
const { t } = useI18n();

const { shouldTrack } = useGetters(COMPLIANCE_MODULE_NAME, ['shouldTrack']);

const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });

const containerClasses = computed(() => {
    const { ctaAlignment, ctaPosition } = props.buttons;
    const isCentered = ctaPosition === 'center';
    const isBottom = ctaPosition === 'bottom';

    return ({
        'c-cta-buttons': true,
        'c-cta-buttons--centered': isBottom || (isCentered && !props.isAbsoluteCentered),
        'c-cta-buttons--centered-absolute': isCentered && props.isAbsoluteCentered,
        'c-cta-buttons--bottom': isBottom,
        [`c-cta-buttons--vertically-${ctaAlignment}`]: true,
        [`c-cta-buttons--${props.numberOfModules}-up`]: !!props.numberOfModules && ctaPosition !== 'below',
    });
});
const buttonVariant = computed(() => {
    const { ctaStyle = '' } = props.buttons;
    const styleParts = ctaStyle.split('-');
    return styleParts[0];
});
const colorVariant = computed(() => {
    const { ctaStyle = '' } = props.buttons;
    const styleParts = ctaStyle.split('-');
    return styleParts[1];
});

function openIntercom() {
    if (shouldTrack.value) {
        window.Intercom('show');
    } else {
        window.location.href = `mailto:${t('global__supportEmailAddress--escaped')}`;
    }
}
function handleButtonClick() {
    if (props.buttons.customEventName) {
        trackGlobalEvent({
            type: CTA_BUTTON_CLICKED,
            data: {
                action: props.buttons.customEventName,
            },
        });
    }
}
</script>

<style lang="scss">
    .c-cta-buttons {
        $this: &;

        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: none;

        @include breakpoint(medium) {
            display: block;
        }

        &--centered {
            display: flex;
            justify-content: center;
        }

        &--centered-absolute {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &--bottom {
            position: absolute;
            bottom: $nu-spacer-3;
            width: 100%;
        }

        &--vertically-center {
            align-items: center;
            text-align: center;
        }

        &--vertically-right {
            text-align: right;
        }

        &__button-container {
            margin-bottom: $nu-spacer-2;

            &:last-child {
                margin-bottom: 0;
            }

            @include breakpoint(medium) {
                margin-bottom: 0;
                margin-right: $nu-spacer-4;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__button {
            pointer-events: auto;
            &--primary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }

            &--primary-light {
                &:hover,
                &:focus,
                &:active {
                    border-color: $nu-white !important;
                }
            }

            &--secondary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                }
            }

            &--secondary-light {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }

            &--link-dark,
            &--link-light {
                height: auto;
                padding: 0;
            }
        }

        &--1-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 20%;
            }
        }

        &--2-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 16%;
            }
        }

        &--3-up {
            padding: 0 12%;
        }

        &--4-up {
            padding: 0 8%;
        }
    }
</style>
