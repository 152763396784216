/* eslint-disable no-param-reassign */
import { computed } from 'vue';
import { useStore } from 'vuex';

function resolveState(store, namespace, key) {
    const getNestedState = ns => (ns ?
        ns.split('/').reduce((state, nsPart) => state[nsPart], store.state) :
        store.state);

    return computed(() => {
        const state = getNestedState(namespace);
        return state[key];
    });
}

function resolveMappedState(store, namespace, map) {
    const getNestedState = ns => (ns ?
        ns.split('/').reduce((state, nsPart) => state[nsPart], store.state) :
        store.state);

    return Object.keys(map).reduce((result, key) => {
        const value = map[key];
        if (typeof value === 'function') {
            result[key] = computed(() => {
                const state = getNestedState(namespace);
                return value(state);
            });
        } else {
            result[key] = resolveState(store, namespace, value);
        }
        return result;
    }, {});
}

export function useState(namespaceOrMap, map) {
    const store = useStore();
    let namespace;
    let resolvedMap;

    if (map) {
        namespace = namespaceOrMap;
        resolvedMap = map;
    } else {
        namespace = null;
        resolvedMap = namespaceOrMap;
    }

    if (Array.isArray(resolvedMap)) {
        return resolvedMap.reduce((result, state) => {
            result[state] = resolveState(store, namespace, state);
            return result;
        }, {});
    }
    return resolveMappedState(store, namespace, resolvedMap);
}

function resolveGetter(store, namespace, key) {
    const getterPath = namespace ? `${namespace}/${key}` : key;
    const getter = store.getters[getterPath];

    if (typeof getter === 'function') {
        return (...args) => store.getters[getterPath](...args);
    }
    return computed(() => store.getters[getterPath]);
}

function resolveMappedGetter(store, namespace, map) {
    return Object.keys(map).reduce((result, key) => {
        const value = map[key];
        if (typeof value === 'function') {
            result[key] = (...args) => {
                const getterPath = namespace ? `${namespace}/${key}` : key;
                return value(store.getters[getterPath](...args));
            };
        } else {
            result[key] = resolveGetter(store, namespace, value);
        }
        return result;
    }, {});
}

export function useGetters(namespaceOrMap, map) {
    const store = useStore();
    let namespace;
    let resolvedMap;

    if (map) {
        namespace = namespaceOrMap;
        resolvedMap = map;
    } else {
        namespace = null;
        resolvedMap = namespaceOrMap;
    }

    if (Array.isArray(resolvedMap)) {
        return resolvedMap.reduce((result, getter) => {
            result[getter] = resolveGetter(store, namespace, getter);
            return result;
        }, {});
    }
    return resolveMappedGetter(store, namespace, resolvedMap);
}

export function useMutations(namespaceOrMap, map) {
    const store = useStore();
    let namespace;
    let resolvedMap;

    if (map) {
        namespace = namespaceOrMap;
        resolvedMap = map;
    } else {
        namespace = null;
        resolvedMap = namespaceOrMap;
    }

    const createMutationCommitter = mutation => (...args) => store
        .commit(namespace ? `${namespace}/${mutation}` : mutation, ...args);

    if (Array.isArray(resolvedMap)) {
        return resolvedMap.reduce((result, mutation) => {
            result[mutation] = createMutationCommitter(mutation);
            return result;
        }, {});
    }

    return Object.keys(resolvedMap).reduce((result, key) => {
        result[key] = createMutationCommitter(resolvedMap[key]);
        return result;
    }, {});
}

export function useActions(namespaceOrMap, map) {
    const store = useStore();
    let namespace;
    let resolvedMap;

    if (map) {
        namespace = namespaceOrMap;
        resolvedMap = map;
    } else {
        namespace = null;
        resolvedMap = namespaceOrMap;
    }

    const createActionDispatcher = action => (...args) => store
        .dispatch(namespace ? `${namespace}/${action}` : action, ...args);

    if (Array.isArray(resolvedMap)) {
        return resolvedMap.reduce((result, action) => {
            result[action] = createActionDispatcher(action);
            return result;
        }, {});
    }

    return Object.keys(resolvedMap).reduce((result, key) => {
        result[key] = createActionDispatcher(resolvedMap[key]);
        return result;
    }, {});
}
