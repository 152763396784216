import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { isFunction, merge, cloneDeep } from 'lodash-es';

import { useActions } from '~coreModules/core/js/composables/vuexHelpers';

import { PAGEVIEW } from '~coreModules/core/js/global-event-constants';
import { GLOBAL_EVENT } from '~coreModules/core/js/store';

export default function useAnalyticsPageView({ disablePageViewInMounted = false, getAnalyticsData = null } = {}) {
    const route = useRoute();
    const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });

    function getParents() {
        const parents = route.matched.map(parent => ({ ...parent }));
        parents.pop();
        return parents;
    }

    function firePageview() {
        if (process.env.VUE_ENV === 'client') {
            let data = { currentPage: cloneDeep(route) };

            if (isFunction(getAnalyticsData)) {
                const pageData = getAnalyticsData.call(this);
                data = merge(data, cloneDeep(pageData));
            }

            data.parents = getParents();

            trackGlobalEvent({
                type: PAGEVIEW,
                data,
            });
        }
    }

    if (!disablePageViewInMounted) {
        onMounted(() => {
            firePageview();
        });
    }

    return {
        firePageview,
    };
}
