import { useSSRContext } from 'vue';

import generateJsonLD from '~coreModules/core/js/seo/json-ld-utils';
import { useUserState } from '~coreModules/core/js/composables/useUserState';

export default function useJsonLd(options = { }) {
    if (process.env.VUE_ENV === 'server') {
        const userState = useUserState();
        const ssrContext = useSSRContext();

        const { SITE_META } = ssrContext;
        const { jsonLDName, jsonLDUrlPath } = SITE_META;

        let jsonLDs = [{
            '@context': 'http://schema.org',
            '@type': 'Corporation',
            name: jsonLDName,
            url: `${userState.baseUrl}${jsonLDUrlPath}`,
            logo: `${userState.baseUrl}/static/logo_630x630.png`,
            sameAs: [
                'https://www.facebook.com/shopnuuly/',
                'https://www.instagram.com/nuuly',
                'https://www.pinterest.com/shopnuuly/',
                'https://twitter.com/shopnuuly',
                'https://www.tiktok.com/@shopnuuly',
            ],
        }];

        if (options?.pageJsonLD) {
            jsonLDs = jsonLDs.concat(options.pageJsonLD);
        }

        ssrContext.jsonLDs = generateJsonLD(jsonLDs);
    }
}
