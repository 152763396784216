import { inject } from 'vue';

export const userStateInjectionKey = Symbol('userState');

/**
 * A composable that provides access to the userState
 * @function
 * @returns {Object} the injected userState made available to the application
 */
export const useUserState = () => inject(userStateInjectionKey);

/**
 * Provides userState to the application
 * @param {Object} app - the application instance
 * @param {Object} userState - the userState instance to be provided
 * @returns {Object} the provided userState instance
 */
export function provideUserState(app, userState) {
    // provide the userState to the app so it can be used in script setup
    app.provide(userStateInjectionKey, userState);
    return userState;
}
