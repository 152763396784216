<template>
    <!-- Product Trays -->
    <Component
        :is="getProductTrayComponentByType(module.trayStrategy, module.title)"
        v-if="module.moduleType === CONTENTFUL_MODULE_TYPES.PRODUCT_TRAY"
        :id="module.title"
        :listId="module.title"
        :listName="module.categorySlug || module.trayStrategy"
        :contentModuleId="module.title"
        :contentTitle="contentTitle"
        :headerTitle="module.displayTitle"
        :headerLink="module.link"
        :headerSubtitle="module.displaySubtitle"
        :categorySlug="module.categorySlug"
        :maxProducts="module.maxProducts"
        :lightButtons="true"
        :showProductInfo="productTrayStyles.showProductInfo"
        :desktopSlideSize="productTrayStyles.desktopSlideSize"
        :mobileSlideSize="productTrayStyles.mobileSlideSize"
        :mobilePeekPercent="productTrayStyles.mobilePeekPercent"
        :paddingBottom="paddingBottom"
        :sublistListId="module.sublistListId"
    />
    <BaseCarouselModule
        v-if="module.moduleType === CONTENTFUL_MODULE_TYPES.CAROUSEL"
        :slides="module.slides"
        :name="module.title"
        :largeBreakpointItemsPerSlide="module.largeBreakpointItemsPerSlide"
    />
    <BasePeekingCarouselModule
        v-else-if="module.moduleType === CONTENTFUL_MODULE_TYPES.PEEKING_CAROUSEL"
        :slides="module.slides"
        :name="module.title"
        :gutters="module.gutters"
    />
    <BaseContentfulModule
        v-else
        :numberOfModules="numberOfModules"
        :module="module"
        :paddingBottom="paddingBottom"
    />
</template>

<script>
import { markRaw, defineAsyncComponent } from 'vue';

import { LARGE_WITH_TILE_PEEK_MOBILE } from '~coreModules/core/js/carousel-constants';
import {
    CONTENTFUL_MODULE_TYPES,
    PRODUCT_TRAY_TYPES,
    PRODUCT_TRAY_TRAY_STYLES,
} from '~modules/contentful/js/contentful-constants';

import BaseContentfulModule from '~coreModules/contentful/components/BaseContentfulModule.vue';
import BaseCarouselModule from '~coreModules/contentful/components/BaseCarouselModule.vue';
import BasePeekingCarouselModule from '~coreModules/contentful/components/BasePeekingCarouselModule.vue';

export default {
    name: 'ContentfulModule',
    components: {
        BaseContentfulModule,
        BaseCarouselModule,
        BasePeekingCarouselModule,
    },
    props: {
        numberOfModules: {
            type: Number,
            required: true,
        },
        module: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        paddingBottom: {
            type: String,
            default: null,
        },
        contentTitle: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            CONTENTFUL_MODULE_TYPES,
            trayTypeMap: {
                [PRODUCT_TRAY_TYPES.catalogV2]: markRaw(
                    defineAsyncComponent(
                        () => import(
                            /* webpackChunkName: "catalogTray" */
                            '~modules/core/components/ui/CatalogTray.vue'),
                    ),
                ),
            },
        };
    },
    computed: {
        productTrayStyles() {
            const { moduleType, trayStyle } = this.module;
            const largeWithTitle = trayStyle === PRODUCT_TRAY_TRAY_STYLES.largeWithTitle;
            const desktopSlideSize = largeWithTitle ? 4 : 5;

            if (moduleType !== CONTENTFUL_MODULE_TYPES.PRODUCT_TRAY) {
                return null;
            }

            return {
                desktopSlideSize,
                showProductInfo: largeWithTitle,
                mobileSlideSize: largeWithTitle ? 1 : undefined,
                mobilePeekPercent: largeWithTitle ? LARGE_WITH_TILE_PEEK_MOBILE : undefined,
            };
        },
    },
    methods: {
        getProductTrayComponentByType(trayStrategy, moduleTitle) {
            const trayComponent = this.trayTypeMap[trayStrategy] || '';

            if (!trayComponent) {
                this.$logger.debugError(`unrecognized ProductTray trayStrategy: ${trayStrategy}, preventing
                    render of module: ${moduleTitle} `);
            }

            return trayComponent;
        },
    },
};
</script>
